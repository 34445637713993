
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');

body {
	font-family: 'Montserrat', Helvetica, Arial, sans-serif; 
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.text-header {
	font-weight: $font-weight-medium;
	color: $primaryLight;
}

h1, .h1 {
	@include font-size(35);
	font-weight: $font-weight-black;

	@media #{$sm} {
		@include font-size(45);
	}
}

h2, .h2 {
	@include font-size(24);
	font-weight: $font-weight-black;
}

h3, .h3 {
	@include font-size(18);
	font-weight: $font-weight-black;
}

h4, .h4 {
	@include font-size(16);
	font-weight: $font-weight-bold;
}

h5, .h5 {
	@include font-size(14);
	font-weight: $font-weight-bold;
}

h6, .h6 {
	@include font-size(12);
}

p, span, li {
	@include font-size(14);
	font-weight: $font-weight-regular;
	color: $grayscaleLight;
}

.footnote, .caption {
	@include font-size(11);
	line-height: 15px;
}

.text-medium {
	@include font-size(14);
}

.text-header {
	@include font-size(15);
}

.text-strong {
	font-weight: $font-weight-medium;
}

.text-action {
	font-weight: $font-weight-medium;
	color: $grayscaleLightest;
}

.text-muted {
	color: $grayscaleLight;
}

.colored {
	color: $primary;
}

.correct {
	color: $correct;
}

.text-left {
	text-align: left;
}

.text-underlined {
	text-decoration: underline;
}

li.colored {
	list-style: none;

	&::before {
		content: '';
		display: inline-block;
		height: 10px;
		width: 17px;
		margin-right: 0.5rem;
		background-image: url("../../images/roadmap/done.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
}