
.center-button,
.dapp-core-component__main__btn-primary {
	border: 0 !important;
	background-color: $primary !important;
	padding: 0.5rem 1.5rem;
	@include border-radius(1rem);

	&:hover {
		background-color: $primaryDark !important;
	}

	&.small {
		@include border-radius(0.65rem);
	}
}

button {	
	color: $grayscaleLightest;

	a {
		color: $grayscaleLightest !important;
		text-decoration: none;

		&:hover {
			color: $grayscaleLightest;
		}
	}

	&:disabled {
		top: 0px !important;
		box-shadow: none !important;
		background-color: transparent !important;
	}
}

.btn-primary {
	background-color: $primary;
	border: 0;

	&:hover {
		background-color: $primaryDark 
	}
}

a {
	color: $primary;

	&:hover {
		color: $primaryLight;
	}
}

/**
* ****** Animated BUTTON *******
*/

.line-button {
	position: relative;
	color: $primary;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	// margin: 0 10px;

	.label {
		padding: 10px;
		display: inline-block;
	}

	&:disabled {
		span { color: $grayscaleDark }
	}

	&.secondary {

		.label {
			padding: 10px 30px;
		}

		.icon {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: skew(-30deg, 0deg);
			transition: all 0.5s;

			&:before,
			&:after {
				content: '';
				height: 1px;
				width: 30px;
				position: absolute;
				display: block;
				background: $primaryLight;
				transition: width 0.5s;
			}

			&:before {
				left: 0;
				top: 0;
			}

			&:after {
				right: 0;
				bottom: 0;
			}

			span {
				&:before,
				&:after {
					content: '';
					background: $primaryLight;
					position: absolute;
					display: block;
					width: 2px;
					height: 30px;
					transition: height 0.5s;
				}

				&:before {
					left: 0;
					top: 0;
				}

				&:after {
					right: 0;
					bottom: 0;
				}
			}
		}

		&.xs {
			.icon {
				&:before,
				&:after {
					width: 10px;
				}
				span {
					&:before,
					&:after {
						height: 10px;
					}
				}
			}
		}

		&:hover {
			.icon {
				transform: scale(0.8);

				&:before,
				&:after {
					width: 100px;
				}

				span {
					&:before,
					&:after {
						height: 30px;
					}
				}
			}
		}
	}

	&.primary {
		.label {
			padding: 8px 12px;
			display: inline-block;
			z-index: 10;
			position: relative;
			white-space: nowrap;
			transition: all 0.225s;
			transition-delay: 0.45s;
			letter-spacing: 2px;
		}

		&.button-start {
			.label {
				padding: 12px 65px;
			}
		}

		&:disabled {
			.icon2 {
				background: $grayscaleLight;
			}
		}

		.icon2 {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: all 0.225s;
			transform: skew(-25deg, 0deg) scaleX(1);
			background: $primary;
			transition-delay: 0.45s;
		}

		.icon {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: all 0.225s;
			transform: scale(0.9) skew(0deg, 0deg);
			transition-delay: 0.15s;

			&:before,
			&:after {
				width: 60px;
				opacity: 0;
				content: '';
				height: 1px;
				position: absolute;
				display: block;
				background: $primaryLight;
				transition: all 0.375s;
				transition-delay: 0.3s;
			}

			&:before {
				top: 0;
				left: calc(50% - 30px);
			}

			&:after {
				bottom: 0;
				right: calc(50% - 30px);
			}

			span {

				&:before,
				&:after {
					height: 0;
					transition-delay: 0s;
					content: '';
					background: $primaryLight;
					position: absolute;
					display: block;
					width: 2px;
					transition: all 0.225s;
				}

				&:before {
					left: 0;
					top: 0;
				}

				&:after {
					right: 0;
					bottom: 0;
				}
			}
		}

		&:hover:enabled {
			.icon {
				transform: skew(-30deg, 0deg);

				&:before,
				&:after {
					transition-delay: 0s;
					width: 30px;
					opacity: 1;
				}

				&:before {
					left: 0;
				}

				&:after {
					right: 0;
				}

				span {
					&:before,
					&:after {
						height: 30px;
						transition-delay: 0.45s;
					}
				}
			}

			.icon2 {
				transform: skew(-30deg, 0deg) scaleX(0.2);
				background: transparent;
				transition-delay: 0s;
				opacity: 0;
			}
		}
	}

	&.xs {
		margin: 0 5px;
		
		.label {
			padding: 0;
		}
		&:hover {
			.icon {
				transform: scale(1);

				&:before,
				&:after {
					width: 10px !important;
				}
				span {
					&:before,
					&:after {
						height: 10px !important;
					}
				}
			}
		}
	}
}