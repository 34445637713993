
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css');
@import 'react-alice-carousel/lib/scss/alice-carousel.scss';
@import 'bootstrap-scss/bootstrap';

// Utils
@import 'styles/util/variables';
@import 'styles/util/resets';
@import 'styles/util/mixins';
@import 'styles/util/position';
@import 'styles/util/typography';

// Components
@import 'styles/components/modal';
@import 'styles/components/button';
@import 'styles/components/input';
@import 'styles/components/slider';
@import 'styles/components/box';
@import 'styles/components/image';
@import 'styles/components/staking';


@import 'styles/general';