html {
	scroll-behavior: smooth;
}

body {
	background-color: $backgroundLight;
	@include font-size(14);
}

/**** Main ***/

#navigation {
	
	.navbar {
		padding-top: $side-spacing;
		padding-left: $side-spacing;
		padding-right: $side-spacing;

		@media #{$md} { 
			padding-top: $side-spacing-mini;
			padding-left: $side-spacing-mini;
			padding-right: $side-spacing-mini;
		}
	}

	.nav-link {
		color: $grayscaleLighter;

		&:hover {
			color: $grayscaleLight;
		}
	}

	.dropdown-menu {
		background-color: $backgroundAlt2;

		.dropdown-item { 
			color: $white; 
			background-color: $backgroundAlt2;

			&:hover {
				background-color: $backgroundDark;
			}
		}
	}
}

#logo {
	color: $primaryLight;

	img {
		padding-right: 0.1rem;
		vertical-align: baseline;
	}

	h3 {
		display: inline-block;
    	vertical-align: baseline;
		margin: 0;
	}
}

.header {
	@media #{$rlg} { 
		min-height: 60vh;
	}
}

.section {
	padding: 4rem $side-spacing;

	@media #{$md} { 
		padding: 2rem $side-spacing-mini;
	}

	.col {
		padding: 0;
	}

	.with-padding {
		padding: 4rem $side-spacing;

		@media #{$md} { 
			padding: 2rem $side-spacing-mini;
		}
	}

	&--header {
		padding: 4rem $side-spacing 0 $side-spacing;

		@media #{$md} { 
			padding: 2rem $side-spacing-mini 0 $side-spacing-mini;
		}
	}

	&--continue {
		padding: 0 $side-spacing 4rem $side-spacing;

		@media #{$md} { 
			padding: 0 $side-spacing-mini 2rem $side-spacing-mini;
		}
	}
}

.section-background-left {
	background-image: url('../images/section-background-left.png');
	background-repeat: no-repeat;
	background-size: contain;
}

.egld-community {
	border-radius: 8px;
	border: none; 
	width: 290px;
	height: 70px;
	margin: 0.5rem 0;
}

#background-text {
	position: absolute;
	top: -3rem;
	left: -1.8rem;
	z-index: -1;
	color: $backgroundAlt2;
	@include font-size(135);
}

.scroller {
	position: relative;
	z-index: 10;
	top: 5rem;

	a {
		display: inline-block;
		position: relative;
		cursor: pointer;
		border-radius: 22px;
		background-color: transparent;
		transition: all .25s linear;
		-webkit-transition: all .25s linear;

		img {
			width: 45px !important;
		}
	
		&:hover {
			background-color: rgba($grayscaleLightest, 0.4);
		}
	}

	@media #{$md} {
		display: none;
	}
}

.row.alt {
	background-color: $backgroundAlt;
}

.block {
	max-width: 520px;
	margin: 0 auto;
}

i.separator {
	position: relative;
	float:left;
	width: 1px;
	height: 30px;
	background: $grayscaleLightest;
}

/**** Content Sections ***/

/**** Header ***/

.main {
	background: radial-gradient(transparent, rgba(2, 8, 18, 0.5));
}

.main-content {
	margin-top: 3rem;

	@media #{$md} { 
		margin-top: $side-spacing-mini;
	}
}


.left-side {
	padding-left: $side-spacing;
	padding-right: $side-spacing;
	padding-bottom: $side-spacing;
	z-index: 10;

	@media #{$md} { 
		padding: $side-spacing-mini;
	}
}

#middle-side {
	position: relative;
	text-align: center;
	z-index: 11;
	padding: 2rem 0;

	@media #{$md} { 
		padding: $side-spacing-mini;
	}

	img {
		position: relative;
		width: 105px;
	}

	h2 {
		margin: 0;
	}

	@media #{$rmd} {
		margin-left: $side-spacing;
		left: 100%;
		@include vendors-generate(transform, translateX(-50%));
	}
}

.left-section {

	.box {
		max-width: 80%;
		position: relative;
		padding: $side-spacing;
		z-index: 2;

		@media #{$md} { 
			padding: $side-spacing-mini;
		}

		&:before {
			content: "";
			background-color: $backgroundDark;
			position: absolute;
			top: 0;
			width: 80%;
			height: 100%;
			z-index: -1;
			margin-left: -$side-spacing;
			@include border-top-right-radius(1rem);
		}
	}
}

.dark {
	background-color: $backgroundDark;

	.box:before {
		background-color: $backgroundLight;
	}
}

.right-side {
	position: relative;
	padding-bottom: $side-spacing;
	padding-left: $side-spacing;
	padding-right: 0;
	overflow: hidden;
	
	@media #{$md} { 
		padding: $side-spacing-mini;
	}

	.right-section {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		.line-button {
			margin-right: 3rem;

			@media #{$md} { 
				margin-right: 2rem;
			}
		}

		.box {
			position: relative;
			max-width: 80%;
			padding: 2rem;
			z-index: 2;
		
			&:before {
				content: "";
				background-color: $backgroundDark;
				position: absolute;
				top: 0;
				left: 30%;
				width: 80%;
				height: 100%;
				z-index: -1;
				@include border-top-left-radius(1rem);
			}
		}
	}
}

.video-banner {
	max-height: 200px;
	@include border-top-right-radius(2rem);
	@include border-bottom-right-radius(2rem);
}

.modal-container.large .modal-dialog {
	max-width: 80%;
}

.modal-container.medium .modal-dialog {
	max-width: 520px;
}

.clickable {
	cursor: pointer;
}

.square {
	width: 100%;
}

.square:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.aspect-ratio-box {
	position: relative;
	height: 0;
	overflow: hidden;
	padding-top: 591.44px / 1127.34px * 100%;
}

.aspect-ratio-box-inside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

video {
	display: block;
}

/**** Features ***/

#features {
	.demo {
		position: relative;
		text-align: center;
		height: 100%;
		
		img {
			max-width: 70%;
		}
	}
}

/**** NFTs ***/

.row-header {
	.right-section {
		padding-top: 4rem;

		@media #{$sm} { padding-top: 1rem; }

		.side-button img {
			object-fit: contain;
		}
	}
}

.bordered {
	text-align: center;
	background-color: $backgroundDark;
	border: 1px solid $primaryLight;
	@include border-radius(1rem);
}

.card {
	position: relative;
	text-align: center;
	background-color: $backgroundDark;
	border: 1px solid $primaryLight;
	@include border-radius(2rem);
	@include horizontal-center();

	.nft-content {
		padding: 1rem;

		img {
			object-fit: contain;
			max-height: 250px;
		}
	}

	.nft-details {
		padding: 1rem;
		border-top: 1px solid $primaryLight;
		background-color: $backgroundAlt;
		@include border-bottom-left-radius(2rem);
		@include border-bottom-right-radius(2rem);
	}

	.nft-details-side {
		padding: 1rem;
		background-color: $backgroundAlt;
		@include border-radius(2rem);
	}

	label {
		color: $primaryLight;
	}

	&.large {
		max-width: 350px;

		.nft-content {
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

/**** Roadmap ***/

#roadmap {
	
	h3 {
		color: $primary;
	}
	
	.item__icon {
		width: 18px;
	}

	.period {
		.item {
			margin-bottom: 0.25rem;
		}
	
		.h--1 { 
			padding-top: 12px; 
			.timeline { height: 10px; }
		}
		.h--2 { 
			padding-top: 9px;
			 .timeline { height: 16px; }
			}
		.h--3 { 
			padding-top: 6px;
			.timeline { height: 22px; }
		}
		.h--4 { 
			padding-top: 3px;
			 .timeline { height: 28px; }
			}
		.h--5 { 
			padding-top: 0px; 
			.timeline { height: 34px; }
		}
	
		.timeline {
			border: 1px solid $primary;
			width: 100%;
			margin-bottom: 0.5rem;
	
			// @media #{$sm} { width: 100px; }
	
			&.complete {
				background: $primary;
			}
	
			&.inprogress {
				background:rgba($primary, 0.4)
			}
		}
	}
}

.alice-carousel__dots-item.__active {
	background-color: $primary !important;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
	cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

span.round {
	display: inline-block;
	text-align: center;
	background-color: #f1f1f1;
	color: black;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	line-height: 32px;
	@include font-size(30);
}

/**** Token benefits ***/

#token-benefits {
	.row {
		text-align: center;
	}

	p {
		margin: 0 auto;
		max-width: 250px;
	}
}

@media #{$rmd} {
	.up-shift {
		bottom: 2rem;
	}
}

@media #{$md} {
	.path-col {
		display: none;
	}
	.spacer {
		margin-bottom: 2rem;
	}
}

.path-image {
	padding: 1rem;
	max-width: 100%;
	max-height: 150px;

	&.blockchain {
		max-height: 120px;
	}
}

.path1, .path2, .path3 {
	padding: 1rem;
	max-height: 70%;
}

.path1 {
	max-width: 100%;
}
.path2 {
	max-width: 80%;
	float: right;
}
.path3 {
	max-width: 70%;
	float: left;
}

/**** Tokenenomics ***/

.token {
	text-align: center;

	img {
		position: absolute;
		@include horizontal-center();
		z-index: 0;
		max-width: 100%;

		@media #{$md} {
			width: 50px;
		}
	}

	&__content {
		position: relative;
		padding: 5rem 0;
		z-index: 1;
	}
}

.icon-item {
	img {
		max-width: 20px;
		max-height: 20px;
		margin-right: 0.5rem;
	}
	span {
		color: $grayscaleLight;
	}
}

@media #{$rmd} {
	.token-details__content {
		position: relative;
		@include vertical-center();
	}
}

@media #{$md} {
	.token {
		order: -1;
	}
	.token-details {
		margin-bottom: 2rem;
		text-align: center;
	}
}

/**** Footer ***/

.social {
	img {
		max-width: 66px;
		padding: 0.5rem;
	}
}