
.staking {
	position: relative;
	max-width: 300px;
	@include horizontal-center();
}

.tag {
	display: inline-block;
	padding: 0.15rem 0.35rem;
	vertical-align: middle;
	border: 1px solid $primaryLight;
	@include border-radius(0.5rem);
}

.flag {
	color: $primaryAlt;
}

.gradient-box {
	position: relative;
	background-color: $backgroundAlt;
	@include border-radius(1.0rem);
	overflow: hidden;

	&.small {
		@include border-radius(0.65rem);
	}

	&.small::before {
		@include border-radius(0.65rem);
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 2px; 
		pointer-events: none;
		background: linear-gradient(320deg, $primary, $secondary); 
		-webkit-mask: 
		   linear-gradient(#fff 0 0) content-box, 
		   linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
				mask-composite: exclude; 
		@include border-radius(1.0rem);
	}

	hr {
		background: linear-gradient(320deg, $primary, $secondary);
		height: 2px;
		border: 0;
		opacity: 1;
		margin: 0;
	}
}

.dropdown-item {
	background-color: $backgroundAlt;

	&:hover {
		background-color: $backgroundLight;
	}
}

.input-container {

	input {
		padding: 8px 16px;
	}

	.rbt-input {
		background-color: $backgroundLighter;
		border-radius: 0.5rem;

		&:focus {
			color: $grayscaleLightest;
		}
	}
}