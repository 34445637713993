
.box-container {
	background-color: $backgroundDark;
	border: 2px solid $primaryLight;
	@include border-radius(2rem);
	padding: 2rem !important;
	margin-bottom: 1rem;

	&.coming-soon {
		border: 2px dotted $primaryLight;
	}

	.tag {
		display: inline-block;
		padding: 0.25rem 0.5rem;
		margin: 0.5rem 0.25rem 0 0;
		border: 1px solid $primaryLight;
		@include border-radius(0.5rem);
	}

	.banner {
		position: relative;
		width: 100%;
		margin: 1rem 0;
	}

	.more {
		display: none;
	}
}