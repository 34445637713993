
body, p {
	margin: 0;
}

#root {
	overflow: hidden;
}

img {
	object-fit: cover;
}

hr {
	border-top: 1px solid $grayscaleLighter;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

button {
	border: 0;
	background-color: transparent;
}

button:focus {
	outline:0;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

.container-fluid {
	padding: 0;
}

.no-padding {
	padding: 0 !important;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

.text-break-all {
	word-break: break-all;
}

.float-image {
	width: 100%;
	height: auto;
}

.display-inline {
	display: inline-block;
	vertical-align: middle;
}

.display-block {
	display: block;
}

.w-40 {
	width: 40%;
}

.w-80 {
	width: 80%;
}

.dapp-core-component__walletConnectLoginContainerStyles__xPortal-container-button,
.dapp-core-component__walletConnectLoginContainerStyles__xPortal-pairing-button {
	background-color: $primary !important;
	color: $primaryLighter !important;
}