
.close span {
	@include font-size(40);
}

.dapp-core-component__dappModalStyles__dappModalContent {
	background-color: $backgroundAlt !important;
	@include border-radius(1rem !important);
	color: $grayscaleLightest !important;
	border: 1px solid $primaryLight !important;
}

.modal-content {
	background-color: $backgroundAlt;
	@include border-radius(1rem);
	color: $grayscaleLightest;
	border: 1px solid $primaryLight;

	th, p, span, td, .btn-link {
		color: $grayscaleLightest;
	}

	a {
		color: $primary;
	}

	div {
		background: transparent;
	}

	.code {
		background-color: $backgroundDark;
		@include border-radius(1rem);
		padding: 1rem;
	}
}

.modal-header {
	border: none;
	margin-bottom: 0;
	padding-bottom: 0;
	justify-content: end !important;
}

.modal-title {
	color: $grayscaleLightest;
}

// Toasts

.toast-header {
	background-color: $primaryLight;
	color: $grayscaleDark;
}

.toast {
	background-color: $backgroundDark;
	border: 1px solid $primaryLight;
}

.dapp-transaction-toast {
	background-color: $backgroundAlt !important;
	border: 1px solid $primaryLight;
	color: $grayscaleLightest;
	right: 0;
	bottom: 0;
}

.dapp-core-component__styles__close {
	color: $grayscaleLightest !important;
}

.dapp-core-component__transactionsToastListStyles__toastWrapper {
	background-color: $backgroundAlt !important;
	color: $grayscaleLightest !important;
}