/**

		- Positions 				.absolute-full, .fixed-full
									.top-0, .right-0, .bottom-0, .left-0

**/
.absolute-full {
	position: absolute !important;
	width: 100%; height: 100%;
	top:0; right: 0; bottom: 0; left: 0;
}
.fixed-full {
	position: fixed !important;
	width: 100%; height: 100%;
	top:0; right: 0; bottom: 0; left: 0;
}
.top-0 {
	top: 0 !important;
}
.bottom-0 {
	bottom: 0 !important;
}
.left-0 {
	left: 0 !important;
	right: auto; // do not use !important
}
.right-0 {
	right: 0 !important;
	left: auto; // do not use !important
}
.start-0 {
	left: 0 !important;
	right: auto !important;	
}

.end-0 {
	right: 0 !important;
	left: auto !important;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

/* vertical align to middle */
.valign-middle {
  position: relative;
  top: 50%;
  @include vendors-generate(transform, translateY(-50%));
}


/* better than .valign-middle */
.d-middle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;

	-webkit-justify-content: center;
	justify-content: center;

	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

/* extra paddings */

.p-6 { padding: 4rem; }
.pt-6 { padding-top: 4rem !important; }
.pb-6 { padding-bottom: 4rem !important; }
.py-6 { padding: 4rem 0 !important; }