.slider {
	-webkit-appearance: none;
	height: 10px;
	border-radius: 5px;
	outline: none;
	-webkit-transition: .2s;
	transition: opacity .2s;
}
  
.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%; 
	background: $primary;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%; 
	background: $primary;
	cursor: pointer;
}

/* Mouse-over effects */
.slider:active {
	&::-webkit-slider-thumb {
		background: $primaryLight;
	}
	&::-moz-range-thumb {
		background: $primaryLight;
	}
}

.slider:hover {
	&::-webkit-slider-thumb {
		background: $primaryLight;
	}
	&::-moz-range-thumb {
		background: $primaryLight;
	}
}

.slider:focus {
    outline: none;
}

.slider:focus::-moz-range-track,
.slider:focus::-webkit-slider-runnable-track {
    background: #ccc;
}