
// View port size

// Use: 
//
//	@media #{$lg} {
//		text-align: center;
//	}
//

$side-spacing: 2rem;
$side-spacing-mini: 1.5rem;

$sm: "(max-width: 575px)";
$md: "(max-width: 767px)";
$lg: "(max-width: 991px)";
$xl: "(max-width: 1199px)";

$rsm: "(min-width: 576px)";
$rmd: "(min-width: 768px)";
$rlg: "(min-width: 992px)";
$rxl: "(min-width: 1200px)";

// Font size

$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-medium: 600;
$font-weight-regular: 400;
$font-weight-light: 300;

// Colors

$black: #1C1F26;

$primary: #FF672A;
$primaryLight: #F2D9BE;
$primaryLighter: #fff6ed;
$primaryAlt: #ee5c21;
$primaryDark: #DA531D;
$primaryDarker: #c54918;

$secondary: #56c4df;

$grayscaleDark: #4B4B4B;
$grayscaleLight: #D7D7D7;
$grayscaleLighter: #F2F2F2;
$grayscaleLightest: #f8f8f8;

$correct: #38AF23;
$wrong: #611B1B;

$backgroundAlt: #07141a;
$backgroundAlt2: #141c23;
$backgroundLight: #0e181f;
$backgroundLighter: #101c25;
$backgroundDark: #011114;

$border: #48525B;
