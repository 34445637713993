

.input-group {
	margin: 0 auto;
	max-width: 520px;
	height: 50px;

	i {
		position: relative;
		margin: 10px 0;
		float:left;
		width: 1px;
		height: 30px;
		background: $border;
	}

	.form-control {
		height: 50px;
		padding: 0 1.5rem;
		border: 1px solid $border;
		color: $grayscaleLightest;
		border-right: none;
		font-size: 0.9rem;
		background-color: transparent;
		@include border-top-left-radius(20px);
		@include border-bottom-left-radius(20px);
	}

	.btn {
		padding: 0 1.5rem;
		margin-left: -1px;
		color: $primary;
		border: 1px solid $border;
		border-left: none;
		@include border-top-right-radius(20px);
		@include border-bottom-right-radius(20px);
		@include border-top-left-radius(0);
		@include border-bottom-left-radius(0);
	}
}

.input-container {
	font-size: 0.9rem;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	border: 1px solid $border;
	color: $grayscaleLightest;
	background-color: $backgroundLight;
	@include border-radius(1rem);
	gap: 0.5rem;

	input {
		border: 0;
		background-color: transparent;
		color: $grayscaleLightest;
		min-width: 50px;

		&:disabled {
			background-color: transparent;
		}
	}

	select {
		background-color: $backgroundAlt2;
		border: 1px solid $backgroundDark;
		@include border-radius(0.6rem);
		color: $primary;
	}
}