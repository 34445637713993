// Flex

@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin order($val) {
    @include vendors-generate(order, $val);
}

// Rotate animation

@mixin prepare-rotation($time) {
    @include vendors-generate(transition-duration, $time);

    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
}

@mixin rotate($degrees) {
    @include vendors-generate(transform, $degrees);
}

// Center elements

@mixin vertical-center() {
    top: 50%;
    @include vendors-generate(transform, translateY(-50%));
}

@mixin horizontal-center() {
    left: 50%;
    @include vendors-generate(transform, translateX(-50%));
}


// Borer radius 

@mixin border-radius($radius) {
    @include vendors-generate(border-radius, $radius);
    background-clip: padding-box;
}

// Single side border-radius

@mixin border-top-left-radius($radius) {
    @include vendors-generate(border-top-left-radius, $radius);
    background-clip: padding-box;
}

@mixin border-top-right-radius($radius) {
    @include vendors-generate(border-top-right-radius, $radius);
    background-clip: padding-box;
}

@mixin border-bottom-left-radius($radius) {
    @include vendors-generate(border-bottom-left-radius, $radius);
    background-clip: padding-box;
}

@mixin border-bottom-right-radius($radius) {
    @include vendors-generate(border-bottom-right-radius, $radius);
    background-clip: padding-box;
}

// Shadow

@mixin box-shadow($left, $top, $blur, $color, $inset: false) {
    @if $inset {
        @include vendors-generate(box-shadow, inset $left $top $blur $color);
    }

    @else {
        @include vendors-generate(box-shadow, $left $top $blur $color);
    }
}


// Font size

@mixin font-size($size) {
    font-size: ($size / 16) + rem;
}

@mixin line-height($size) {
    line-height: ($size / 16) + rem;
}

// Transform

@mixin transform-translateY($procent) {
    @include vendors-generate(transform, translateY($procent));
    transform: translateY($procent);
}

@mixin transform-translateX($procent) {
    @include vendors-generate(transform, translateX($procent));
    transform: translateX($procent);
}

// Rotate - ex: rotate(90deg)

@mixin rotate($degrees) {
    transform: rotate($degrees);
    @include vendors-generate(transform, rotate($degrees));
}

// @include vendors-generate(box-shadow, 0 10px 40px 10px #333333 !important);
$vendors: "-moz-",
"-webkit-",
"-ms-",
"-o-",
"";

@mixin vendors-generate($css-rule, $css-value) {
    @each $vendor in $vendors {
        #{$vendor}#{$css-rule}: $css-value;
    }
}

// $degrees example: 90deg
@mixin gradient($color1, $color2, $degrees) {
    background: $color1;
    background: linear-gradient($degrees, $color1 0%, $color2 100%);
}